<template>
  <main class="px-2 md:px-4 py-6 flex flex-col gap-4">
    <h4
      class="capitalize md:uppercase md:text-sm text-xs font-medium text-headerText"
    >
      Book Keeping / Invoice / Add
    </h4>
    <div>
      <button
        @click="showPreview ? (showPreview = false) : $router.go(-1)"
        class="font-medium text-dark-800 focus:outline-none text-base flex items-center"
      >
        <i class="fas fa-angle-left mr-2 cursor-pointer"></i> Back
      </button>
    </div>

    <PreviewInvoice
      v-show="showPreview"
      @save="handleSave"
      :saving="saving"
      :formDetails="formDetails"
      :addForm="!showButtons"
      :showPay="createdInvoice._id ? true : false"
      :createdInvoice="createdInvoice._id"
      :createdCompanyId="createdInvoice.companyId"
    />

    <div v-show="!showPreview" class="bg-white rounded-3xl py-10">
      <div
        class="relative overflow-hidden flex mx-auto items-center justify-between gap-4 w-72 my-4 mb-6"
      >
        <div
          :class="
            activeTab == 1
              ? 'bg-secondary text-white'
              : 'bg-white text-dark-700 border-dark-100'
          "
          class="w-7 h-7 text-xs rounded-full border flex items-center justify-center z-10"
        >
          1
        </div>
        <div
          :class="
            activeTab == 2
              ? 'bg-secondary text-white'
              : 'bg-white text-dark-700 border-dark-100'
          "
          class="w-7 h-7 text-xs rounded-full border flex items-center justify-center z-10"
        >
          2
        </div>
        <div
          :class="
            activeTab == 3
              ? 'bg-secondary text-white'
              : 'bg-white text-dark-700 border-dark-100'
          "
          class="w-7 h-7 text-xs rounded-full border flex items-center justify-center z-10"
        >
          3
        </div>
        <hr class="border-dark-100 absolute left-0 w-full" />
      </div>

      <AddInvoice @step="gotoStep" @preview="handlePreview" />
    </div>

    <easiAlert
      v-if="saveModal"
      title="Save Invoice"
      caption="Invoice saved successfully!"
    >
      <div class="w-11/12 mx-auto">
        <easiButton size="medium" @click="saveModal = false; $router.push('/book-keeping/invoice')" block
          >Dismiss</easiButton
        >
      </div>
    </easiAlert>


  </main>
</template>

<script setup>
import { computed } from "vue";
import AddInvoice from "@/components/BookKeeping/Invoice/AddInvoice.vue";
import PreviewInvoice from "@/components/BookKeeping/Invoice/PreviewInvoice.vue";

import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";
import { addClient } from "@/services/book-keeping/client";


const store = useDataStore();
const { query, mutate } = store;
import { ref, reactive } from "vue";

const toast = useToast();
const showButtons = ref(false);
const createdInvoice = ref({_id:''});
const saving = ref(false);



const activeTab = ref(1);
const gotoStep = (e) => {
  activeTab.value = e;
};
const saveModal = ref(false);



const handleSave = async (invoiceNo) => {
  saving.value = true;
  let client = formDetails.value.client;
  const {
    companyName,
    city,
    state,
    country,
    note,
    firstName,
    lastName,
    address,
    phone,
    email,
  } = formDetails.value;
  // if no client, create client
  if (!formDetails.value.client) {
    const clientPayload = {
      address,
      lastName,
      firstName,
      phone,
      email,
      companyName,
    };
    const res = await addClient({ ...clientPayload, isSave: true });
    if (res._id) {
      client = res._id;
    }
  }
  let currentDate = new Date();



  const payload = {
    client,
    items: formDetails.value.items.map((each) => ({
      name: each.itemName,
      amount: each.unit,
      quantity: Number(each.quantity),
      inventory:each.id
    })),
    companyName,
    notes: note,
    billingAddress: formDetails.value.address,
    city,
    state,
    country,
    postalCode: formDetails.value.postal,
    deliveryFee: Number(formDetails.value.fee),
    discount: Number(formDetails.value.discount),
    invoiceNo,
    autoVat: Number(formDetails.value.tax),
  };

  try {
    let res = await mutate({
      endpoint: "CreateBookKeepingInvoices",
      data: { input: payload },
      service: "BOOKKEEPING",
    });

    if (res.success === true) {
      saveModal.value = true;
      showButtons.value = true;
      createdInvoice.value = res.data;
    } else {
      toast.error("Invoice creation failed.");
    }
  } catch (e) {
    toast.error(e.message);
  } finally {
    saving.value = false;
  }
};
const formDetails = ref({});
const showPreview = ref(false);
const handlePreview = (e) => {
  console.log('previes is',e)
  formDetails.value = e;
  showPreview.value = true;
};
</script>

<style></style>
